<template>
  <section class="expert-approval">
    <div :class="innerClasses">
      <div class="expert-approval__label">
        <h5 class="expert-approval__label-text">Статья проверена специалистом</h5>
      </div>
      <a class="expert-approval__name" :href="href">
        <span>{{ name }}</span><sup class="expert-approval__asterisk">*</sup>
      </a>
      <div class="expert-approval__description">
        <p>{{ description }}</p>
      </div>
      <a class="expert-approval__picture" v-if="imageSrc" :href="href">
        <picture>
          <source :srcset="imageSrcset">
          <img :src="imageSrc" :alt="'Фотография эксперта ' + name" loading="lazy">
        </picture>
      </a>
      <div class="expert-approval__extra">
        <div class="expert-approval__extra-content">
          <slot name="extra-content"></slot> <!-- Разметка передаётся через слот -->
        </div>
      </div>
      <div class="expert-approval__disclaimer">
        <span><span>*</span> В соответствии с ч. 9 ст. 10.1 Федерального закона №152-ФЗ «О персональных данных», обработка персональных данных субъекта неограниченным кругом лиц запрещена, за исключением доступа.</span>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "ExpertApproval",
  props: {
    name: { type: String, required: true, },
    description: { type: String, required: true, },
    href: { type: String, required: true, },
    imageSrc: { type: String, required: false, },
    imageSrcset: { type: String, required: false, },
  },

  computed: {
    innerClasses() {
      return {
        'expert-approval__inner': true,
        'expert-approval__inner--no-pic': !this.imageSrc,
      };
    },
  },
};
</script>

<style lang="scss">
@use '/frontend/scss/base/media.scss' as media;
@use '/frontend/scss/base/variables.scss' as var;

.expert-approval {
  color: #575F75;
  background-color: #F2FAFF;
  @include media.fluid('border-radius', 24px);
  @include media.fluid('padding', 20px 24px);
  @include media.fluid('margin-bottom', 20px, 30px);

  font-family: 'CeraRoundPro';

  &__inner {
    display: grid;
    @include media.fluid('grid-template-columns', auto 84px, 84px auto);
    @include media.fluid('column-gap', 16px);

    &--no-pic {
      @include media.fluid('grid-template-columns', auto 0px, 0px auto);
      @include media.fluid('column-gap', 0px);
    }
  }

  &__label {
    grid-column: 2 / 3;
    grid-row: 1 / 2;

    @include media.bp-till(media.$sm) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }

    @include media.fluid('margin-bottom', 16px, 4px);

    &-text {
      position: relative;
      @include media.fluid('padding-left', 22px);

      @include media.fluid('font-size', 14px);
      @include media.fluid('line-height', 24px);
      font-weight: 500;
      color: #96CA00;

      &::before {
        content: '';
        position: absolute;
        @include media.fluid('width', 14px);
        @include media.fluid('height', 15px);
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background: url('/frontend/assets/img/expert-approval/expert-approval.svg') center / cover no-repeat;
      }
    }
  }

  &__name {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    text-decoration: none;
    @include media.fluid('gap', 8px);

    @include media.bp-till(media.$sm) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }

    color: #005DAA;
    @include media.fluid('font-size', 18px, 28px);
    @include media.fluid('line-height', 24px, 32px);
    font-weight: 700;

    @include media.fluid('margin-bottom', 8px);
  }

  &__description {
    grid-column: 2 / 3;
    grid-row: 3 / 4;

    @include media.bp-till(media.$sm) {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
    }

    /* color: #575F75; */
    @include media.fluid('font-size', 12px);
    @include media.fluid('line-height', 16px);
    font-weight: 400;

    @include media.fluid('margin-bottom', 16px);
  }

  &__picture {
    grid-column: 1 / 2;
    grid-row: 1 / 4;

    @include media.bp-till(media.$sm) {
      grid-column: 2 / 3;
      grid-row: 2 / 4;
    }

    align-self: start;
    border-radius: 50%;
    overflow: hidden;

    & img {
      @include media.fluid('width', 84px);
      @include media.fluid('height', 84px);
    }

    .expert-approval__inner--no-pic & {
      display: none;
    }
  }

  &__extra {
    grid-column: 2 / 3;
    grid-row: 4 / 5;

    @include media.bp-till(media.$sm) {
      grid-column: 1 / 3;
      grid-row: 4 / 5;
    }

    @include media.fluid('font-size', 12px);
    @include media.fluid('line-heigh', 16px);
    font-weight: 400;

    &-content {
      // Стили для контентщика, что бы из админинки можно было ставить
      & .color-cyan {
        color: var.$colorCyan;
      }
      & .text-bold {
        font-weight: 700;
      }
    }
  }

  &__disclaimer {
    grid-column: 2 / 3;
    grid-row: 5 / 6;
    @include media.fluid('padding-top', 16px);
    @include media.fluid('font-size', 10px);
    @include media.fluid('line-heigh', 14px);

    @include media.bp-till(media.$sm) {
      grid-column: 1 / 3;
      grid-row: 5 / 6;
    }
  }

  &__asterisk {
    @include media.fluid('font-size', 12px);
    /* @include media.fluid('line-height', 24px); */
  }
}
</style>
