var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"select",staticClass:"custom-select",class:[
    { 'custom-select_opened': _vm.isOpen },
    { 'custom-select_disabled': !_vm.dataArray.length },
    { _error: _vm.v && _vm.v.$error },
    { 'custom-select_gray': _vm.gray },
  ],on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],attrs:{"type":"hidden","name":_vm.name},domProps:{"value":(_vm.inputValue)},on:{"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}}),_c('div',{class:[
      'custom-select__value',
      { 'custom-select__value_empty': _vm.isEmpty },
    ],on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}},[(_vm.isEmpty)?[(!_vm.alwaysShowPlaceholder)?_c('span',{staticClass:"g-hidden-tablet"},[_vm._v(" Выберите... ")]):_vm._e(),_c('span',{class:{ 'g-visible-tablet': !_vm.alwaysShowPlaceholder }},[_vm._v(" "+_vm._s(_vm.placeholder)+" ")])]:[_c('span',[_vm._v(_vm._s(_vm.text))])]],2),_c('svg',{staticClass:"custom-select__arrow",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"}})]),(!_vm.isEmpty)?_c('div',{staticClass:"custom-select__reset",attrs:{"title":"Сбросить"},on:{"click":_vm.reset}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","xml:space":"preserve","viewBox":"0 0 384.1 384.2"}},[_c('path',{attrs:{"d":"M373.6 322.7 243 192.1 373.6 61.5c14.1-14.1 14.1-36.8 0-50.9s-36.8-14.1-50.9 0L192.1 141.2 61.5 10.6c-14.1-14.1-36.8-14.1-50.9 0s-14.1 36.8 0 50.9l130.6 130.6L10.6 322.7c-14.1 14.1-14.1 36.8 0 50.9s36.8 14.1 50.9 0L192.1 243l130.6 130.6c14.1 14.1 36.8 14.1 50.9 0 14-14.1 14-36.9 0-50.9z"}})])]):_vm._e(),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.isOpen)?_c('vue-custom-scrollbar',{ref:"dummyWrap",staticClass:"custom-select__list"},[_c('ul',{},_vm._l((_vm.dataArray),function(item,i){return _c('li',{key:i,on:{"click":function($event){return _vm.selectOption(item)}}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }