var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['filter', 'filter_no-padding', 'filter--newborn', {'filter_closed': !_vm.opened}]},[_c('div',{staticClass:"filter__inner"},[_vm._m(0),_c('transition',{attrs:{"name":"slide"}},[(_vm.opened || !_vm.isMobile)?_c('div',{staticClass:"filter__theme"},_vm._l((_vm.themes),function(theme,index){return _c('a',{key:index,class:[
             'filter__theme-item',
             'filter__theme-item_' + theme.code,
             {
               'filter__theme-item_selected': _vm.checkSelected(theme.code)
             }
           ],attrs:{"href":`${_vm.url}?themes=${theme.code}`},on:{"click":function($event){$event.preventDefault();return _vm.selectTheme(theme.code)}}},[_vm._v(" "+_vm._s(theme.name)+" ")])}),0):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"filter__heading"},[_vm._v("выберите "),_c('span',[_vm._v("тему")])])
}]

export { render, staticRenderFns }