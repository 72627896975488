var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}],class:['filter', 'filter_no-padding', 'filter--newborn', {'filter_closed': !_vm.opened}]},[(_vm.isMobile)?_c('div',{staticClass:"filter__toggler-alt",on:{"click":_vm.toggle}}):_vm._e(),_c('div',{staticClass:"filter__inner"},[_c('h3',{staticClass:"filter__heading",on:{"click":_vm.toggle}},[_vm._v("выберите "),_c('span',[_vm._v("тему")])]),_c('transition',{attrs:{"name":"slide"}},[(_vm.opened || !_vm.isMobile)?_c('div',{staticClass:"filter__theme"},_vm._l((_vm.sections),function(section,index){return _c('a',{key:index,class:[
             'filter__theme-item',
             'filter__theme-item_' + section.code,
             {
               'filter__theme-item_selected': _vm.checkSelected(section.code)
             }
           ],attrs:{"href":`#`},on:{"click":function($event){$event.preventDefault();return _vm.selectSection(section.code)}}},[_vm._v(" "+_vm._s(section.name)+" ")])}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }